import { createSlice } from "@reduxjs/toolkit";


const CalculateTotalScoreSlice = createSlice({
    name: 'totalScore',
    initialState: 0,
    reducers: {
        setScore(state, action) {
            return state = 0
        },
        addScore(state, action) {
            return state = state + action.payload
        },
        removeScore(state, action) {
            return state = state - action.payload
        }
    }
})

export const { setScore, addScore, removeScore } = CalculateTotalScoreSlice.actions
export default CalculateTotalScoreSlice.reducer