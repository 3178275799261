import React, { createContext, useState } from "react";

export const CalculateProgressContext = createContext();

export const CalculateProgressProvider = props => {
    const [calculate, setCalculate] = useState(0);
    const [pageValue, setPageValue] = useState(0);
return(
    <div>
        <CalculateProgressContext.Provider value={[calculate, setCalculate, pageValue, setPageValue]}>
            {props.children}
        </CalculateProgressContext.Provider>
    </div>
)
}