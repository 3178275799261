import emailjs from '@emailjs/browser';

export function sendEmail(subject, to_name, email_1, message, company_name, company_type, email, phone, reply_to, result, questions) {
    let info = {};
    info['to_name'] = to_name;
    info['email_1'] = email_1;
    info['message'] = message;
    info['company_name'] = company_name;
    info['company_type'] = company_type;
    info['email'] = email;
    info['phone'] = phone;
    info['reply_to'] = reply_to;
    info['result'] = result;
    info['questions'] = questions;
    info['subject'] = subject;


    emailjs.send('gmail_davide', 'template_6xc0hcw', info, 'OXBUvWZsDn687e0XE')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
}

export function sendEmailCustumers(to_name, message, reply_to_gdprok, result) {
    let info = {};
    info['to_name'] = to_name;
    info['message'] = message;
    info['reply_to'] = reply_to_gdprok;
    info['result'] = result;

    emailjs.send('gmail_davide', 'template_fse87uc', info, 'OXBUvWZsDn687e0XE')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
}