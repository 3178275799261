import { createSlice } from '@reduxjs/toolkit'

const initialState = { page: 0 }

const NumberOfPageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        setPage(state) {
            state.page = 0
        },
        increment(state) {
            state.page++
        },
        decrement(state) {
            state.page--
        },
    },
})

export const { setPage, increment, decrement } = NumberOfPageSlice.actions
export default NumberOfPageSlice.reducer