import React, { useContext, useEffect } from 'react';
import './css/resultPage.css';
import Confetti from '../../../images/confetti.png';
import { useSelector } from 'react-redux';
import { FormDetailsContext } from '../../../context/FormDetailsContext';
import { sendEmailCustumers } from 'utils/emailReport';
import { sendEmail } from '../../../utils/emailReport';

function ResultPage({email_1, email_2}) {
    const {companyName, setCompanyName, companyType, email, phone, formStatus, setFormStatus} = useContext(FormDetailsContext);
    const positionPage = useSelector((state) => state.positionPage.page);
    const lastUrl = useSelector((state) => state.lastUrl);
    const questionGroup = useSelector((state) => state.questionGroup);
    const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);
    const totalScore = useSelector((state) => state.totalScore);
    const result = totalScore;
    const message =  'Dati inviati dal questionario GDPR.';

// gestione risposte
let i = 0;
let arrSelected = [];
questionGroup.map((item) => {
    console.log("SONO L'URL: ", lastUrl[i])
if(item.path === lastUrl[i]){
    console.log("SONO IO:", item);
    arrSelected.push(item);
    i++
}
})
    let newArr = [];
    arrSelected.map((item) => {
        if(item.question && item.answer){
            let question = item.question;
            let answer = item.answer;
            newArr.push({question, answer})
        }
    })
    const QuestionResult = JSON.stringify(newArr).replace(/(<([^>]+)>)/ig, '');
    console.log("RISULTATO: ", QuestionResult)
// gestione risposte



    let description = "";
    let circleStyle ={
        borderColor: "",
        backgroundColor: "",
    }

    let TypeCompany;
    switch (companyType) {
        case 0:
          TypeCompany = 'Spa'
          break;
        case 1:
          TypeCompany = 'Srl'
          break;
          case 2:
            TypeCompany = 'Associazione'
            break;
        default:
            TypeCompany = 'Non selezionata'
      }
      

    useEffect(() => {
        sendEmail('Risultato finale', email_1, email_2, message, companyName, TypeCompany, email, phone, email, result, QuestionResult );
        sendEmailCustumers(email, description, email_1, result)
    }, [])
    

  
    if(requestSurvey && requestSurvey[positionPage]){
        const question = requestSurvey[positionPage];
        let sendMessage = false;

        if(result < 40){
            description = question.danger;
            circleStyle.borderColor = "#E82B2B";
            circleStyle.backgroundColor = "#FC9F9F";
            sendMessage = true;
        }else if(result >= 40 && result < 70){
            description = question.warning;
            circleStyle.borderColor = "#D6AF28";
            circleStyle.backgroundColor = "#FEEFB8";
            sendMessage = true;
        }else if(result > 70){
            description = question.success;
            circleStyle.borderColor = "#099C32";
            circleStyle.backgroundColor = "#AAF5BF";
            sendMessage = true;
        }
  return(

<div className='container__tgd__question tgd__container__result'>
        <div className='container__tgd__question__main tgd__main__result'>
          {/* contenuto che cambia */}
          <div className='tgd__container__result__title'>
              <h2>{question.title}</h2>
          </div>
    <div className="tgd__container__body">
    <div className='tgd__container__result__resultPage'>
        <img src={Confetti} alt="confetti" />
        <div className='tgd__container__result__resultPage__circle' style={circleStyle}>
            <h1>{result}</h1>
        </div>
        
    </div>
    <div className='tgd__container__result__description'>
    <p dangerouslySetInnerHTML={{ __html: description }}></p>    
    </div>
    </div>
           {/* contenuto che cambia */}
        </div>
     
        </div>
    
  );

    }else{
        return(
            <>
            </>
        );
    }
}

export default ResultPage;
