import React from 'react';
import './css/buttonBackCustom.css';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { decrement } from 'app/slices/NumberOfPageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { removeScore } from 'app/slices/calculateTotalScoreSlice';
import { deleteLastValue } from 'app/slices/lastValueAnswer';
import { useNavigate } from 'react-router-dom';
import { deleteURL } from 'app/slices/lastURLSlice';

function ButtonBackCustom({nav}) {
  let navigate = useNavigate();
    const lastValue = useSelector((state) => state.lastValue);
    const dispatch = useDispatch();
    const last = lastValue[lastValue.length - 1];

  return (
    <div className='tgd__buttonBack__wrap'
    onClick={() => {
        dispatch(decrement());
        dispatch(removeScore(last));
        dispatch(deleteLastValue());
        dispatch(deleteURL());
        navigate(nav)
      }}>

         <ArrowBackRoundedIcon color="greyLight" className="arrow-buttonBack"/>
         <h6>Indietro</h6>
    </div>
  )
}

export default ButtonBackCustom