import { createSlice } from "@reduxjs/toolkit";


const LastURLSlice = createSlice({
    name: 'lastValue',
    initialState: [],
    reducers: {
        setURL(state, action) {
            state.push(action.payload)
        },
        deleteURL(state, action) {
            state.pop()
        },
        reseteURL(state, action) {
            return state = []
        }
    }
})

export const { setURL, deleteURL, reseteURL } = LastURLSlice.actions
export default LastURLSlice.reducer