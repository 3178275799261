import React from 'react';
import './css/introSurvey.css';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { increment } from 'app/slices/NumberOfPageSlice';
import { useNavigate } from 'react-router-dom';

function IntroSurvey() {
  const positionPage = useSelector((state) => state.positionPage.page);
  const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  function handleButtonClick(){
      dispatch(increment());
      navigate('/step-1')
  }
  
  if(requestSurvey && requestSurvey[positionPage]){
    const question = requestSurvey[positionPage];
    return (
      <div className='container__tgd__question'>
        <div className='container__tgd__question__main'>
          {/* contenuto che cambia */}
          <div className="tgd__intro__title">
            <h2>{question.title}</h2>
          </div>
          <div className="tgd__intro__body">
          <p dangerouslySetInnerHTML={{__html: question.description}}></p>
          </div>
           {/* contenuto che cambia */}
        </div>
        <div className='container__tgd__question__button'>
            {/* contenuto che cambia */}
            <Button variant="contained" color="primary" onClick={handleButtonClick}>Inizia</Button>
           {/* contenuto che cambia */}
  </div>
        </div>
    )
  }else{
    return(
        <div>
          <h2>Qualcosa è andato storto!</h2>
        </div>
    );
  }

}

export default IntroSurvey