import React from "react";
import "./main.css";
import Header from "./header/Header";
import Body from "./body/Body";

function Main({color, logo, title, email_1, email_2}) {
    return (
      <div className="tgd__main">
        <div className="tgd__main__header">
          <Header color={color} logo={logo} title={title}/>
        </div>
        <div className="tgd__main__body">
          <Body email_1={email_1} email_2={email_2} />
        </div>
      </div>
    );
}

export default Main;
