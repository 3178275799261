import React, { createContext, useState } from "react";

export const FormDetailsContext = createContext();

export const FormDetailsProvider = (props) => {
  const [companyName, setCompanyName] = useState("");
  const [companyType, setCompanyType,] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [formStatus, setFormStatus] = useState(false);
  const [ checkboxCheck, setCheckboxCheck ] = useState(false);

  return (
    <div>
      <FormDetailsContext.Provider value={{companyName, setCompanyName, companyType, setCompanyType, email, setEmail, phone, setPhone, formStatus, setFormStatus, checkboxCheck, setCheckboxCheck}}>
        {props.children}
      </FormDetailsContext.Provider>
    </div>
  );
};
