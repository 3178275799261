import React from "react";
import './stepAnswer.css';
import { useSelector } from 'react-redux';
import { CalculateProgressContext } from '../../../../context/CalculateProgressContext';

function StepAnswers() {
  const [calculate, setCalculate, pageValue, setPageValue] = React.useContext(CalculateProgressContext);
  const positionPage = useSelector((state) => state.positionPage.page);
  const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);

  console.log("CALCULATE: ", calculate)
  // {positionPage} of {requestSurvey.length - 2} answers
  return (
    <>
      <h6 className="percentage-number" style={{ textAlign: "end", marginLeft: "14px" }}>{Math.floor(calculate)}%</h6>
    </>
  );
}

export default StepAnswers;
