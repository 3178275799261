import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequestSurveyService } from '../service';

export const sendGetRequestSurvey = createAsyncThunk(
    'surveyRequest/sendGetRequestSurvey',
    async(payload, { dispatch }) => {
        return await getRequestSurveyService()
    }
)

const getRequestSurveySlice = createSlice({
    name: 'surveyRequest',
    initialState: {
        data: [],
        status: null
    },
    extraReducers: {
        [sendGetRequestSurvey.pending]: (state, action) => {
            state.status = 'loading'
        },
        [sendGetRequestSurvey.fulfilled]: (state, { payload }) => {
            state.data = payload
            state.status = 'Success'
        },
        [sendGetRequestSurvey.rejected]: (state, action) => {
            state.status = 'failed'
        }
    },
})

export default getRequestSurveySlice.reducer