import React, { createContext, useState } from "react";

export const TotalScoreContext = createContext();

export const TotalScoreProvider = props => {
    const [total, setTotal] = useState(0);
return(
    <div>
        <TotalScoreContext.Provider value={[total, setTotal]}>
            {props.children}
        </TotalScoreContext.Provider>
    </div>
)
}