import { configureStore } from '@reduxjs/toolkit';
import NumberOfPageReducer from './slices/NumberOfPageSlice';
import getRequestSurveyReducer from './slices/getRequestSurvey';
import CalculateTotalScoreReducer from './slices/calculateTotalScoreSlice';
import LastValueAnswerReducer from './slices/lastValueAnswer';
import LastURLSlice from './slices/lastURLSlice';
import PositionSelectReducer from './slices/positionSelectSlice';
import QuestionAnswersReducer from './slices/questionsAnswersSlice/questionsAnswers';

export const store = configureStore({
    reducer: {
        positionPage: NumberOfPageReducer,
        requestSurvey: getRequestSurveyReducer,
        totalScore: CalculateTotalScoreReducer,
        lastValue: LastValueAnswerReducer,
        lastUrl: LastURLSlice,
        positionSelect: PositionSelectReducer,
        questionGroup: QuestionAnswersReducer
    },
});