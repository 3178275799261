import React, { useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@mui/material';
import { increment } from 'app/slices/NumberOfPageSlice';
import { useNavigate } from 'react-router-dom';
import FormDetailsClient from './form/FormDetailsClient';
import PopoverCustom from '../../../../components/popover/PopoverCustom';
import { FormDetailsContext } from '../../../../context/FormDetailsContext';
import { sendEmail } from '../../../../utils/emailReport';

function Step_1({email_1, email_2}) {
  const {companyName, setCompanyName, companyType, email, phone, formStatus, setFormStatus} = useContext(FormDetailsContext);
  const positionPage = useSelector((state) => state.positionPage.page);
  const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const message =  'Dati inviati dal questionario GDPR.';
  const resultMessage = 'Questa Email arriva dal completamento del primo Step, il questionario non è ancora terminato, per tanto non è stato possibile reperire il risultato finale. Al termine del questionario riceverai una seconda Email.'


  let TypeCompany;
  switch (companyType) {
      case 0:
        TypeCompany = 'Spa'
        break;
      case 1:
        TypeCompany = 'Srl'
        break;
        case 2:
          TypeCompany = 'Associazione'
          break;
      default:
          TypeCompany = 'Non selezionata'
    }

  function handleButtonClick(){
      dispatch(increment());
      sendEmail('Form Iniziale', email_1, email_2, message, companyName, TypeCompany, email, phone, email, resultMessage );
      navigate('/step-2')
  }
  
  if(requestSurvey && requestSurvey[positionPage]){
    const question = requestSurvey[positionPage];
    if(question.required ){
      setFormStatus(true)
    }
    return (
      <div className='container__tgd__question'>
        <div className='container__tgd__question__main tgd__main__form'>
          {/* contenuto che cambia */}
          <div className="tgd__step__title">
          {question.info && ( <PopoverCustom info={question.info} /> )}
            <h2 dangerouslySetInnerHTML={{ __html: question.title}}></h2>
          </div>
          <div className="tgd__step__body" style={{justifyContent: "center"}}>
          <FormDetailsClient />
          </div>
           {/* contenuto che cambia */}
        </div>
        <div className='container__tgd__question__button'>
            {/* contenuto che cambia */}
            { formStatus === false ? (<Button variant="contained" color="primary" onClick={handleButtonClick}>Prossima domanda</Button>) : (
              <Button disabled variant="contained" color="primary" onClick={handleButtonClick}>Prossima domanda</Button>
            )}
           {/* contenuto che cambia */}
  </div>
        </div>
    )
  }else{
    return(
        <div>
          <h2>Qualcosa è andato storto!</h2>
        </div>
    );
  }

}

export default Step_1