import { createSlice } from "@reduxjs/toolkit";


const QuestionAnswersSlice = createSlice({
    name: 'questionsAnswers',
    initialState: [],
    reducers: {
        setQuestions(state, { payload }) {
            state.push(payload)
        },
        deleteQuestions(state, action) {
            state.pop()
        },
        reseteQuestions(state, action) {
            return state = []
        },
        updateQuestions(state, {payload}){
            state.map((item, index) => {
                if(item.path === payload.path){
                    state.splice(index, 1, payload);  
                }
            })   
        }
    }
})

export const { setQuestions, deleteQuestions, reseteQuestions, updateQuestions  } = QuestionAnswersSlice.actions
export default QuestionAnswersSlice.reducer