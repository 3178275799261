import { createSlice } from "@reduxjs/toolkit";


const LastValueAnswerSlice = createSlice({
    name: 'lastValue',
    initialState: [],
    reducers: {
        setLastValue(state, action) {
            state.push(action.payload)
        },
        deleteLastValue(state, action) {
            state.pop()
        },
        reseteLastValue(state, action) {
            return state = []
        }

    }
})

export const { setLastValue, deleteLastValue, reseteLastValue } = LastValueAnswerSlice.actions
export default LastValueAnswerSlice.reducer