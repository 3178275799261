import React from 'react';
import './css/buttonBackChildren.css';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useSelector, useDispatch } from 'react-redux';
import { removeScore } from 'app/slices/calculateTotalScoreSlice';
import { deleteLastValue } from 'app/slices/lastValueAnswer';
import { useNavigate } from 'react-router-dom';
import { deleteURL } from 'app/slices/lastURLSlice';
import { deleteQuestions } from 'app/slices/questionsAnswersSlice/questionsAnswers';

function ButtonBackChildren({nav}) {
  let navigate = useNavigate();
    const lastValue = useSelector((state) => state.lastValue);
    const dispatch = useDispatch();
    const last = lastValue[lastValue.length - 1];
   

  return (
    <div className='tgd__buttonBackChildren__wrap'
    onClick={() => {
        dispatch(removeScore(last));
        dispatch(deleteLastValue());
        dispatch(deleteURL());
        navigate(nav)
      }}>

         <ArrowBackRoundedIcon color="greyLight" className="arrow-buttonBack"/>
         <h6>Indietro</h6>
    </div>
  )
}

export default ButtonBackChildren