import React, { useContext } from "react";
import "./header.css";
import logoDefault from "../../../../images/logo.png";
import { setPage } from "../../../../app/slices/NumberOfPageSlice";
import { setScore } from "../../../../app/slices/calculateTotalScoreSlice";
import { useDispatch, useSelector } from "react-redux";
import LinearBarProgress from "../../../../components/common/linear-bar-progress/LinearBarProgress";
import { useNavigate } from "react-router-dom";
import { FormDetailsContext } from "../../../../context/FormDetailsContext";
import { reseteLastValue } from "app/slices/lastValueAnswer";
import { reseteURL } from "app/slices/lastURLSlice";
import { reseteLastPosition } from "app/slices/positionSelectSlice";
import { reseteQuestions } from "app/slices/questionsAnswersSlice/questionsAnswers";

function Header({color, logo, title}) {
  const {
    companyName,
    setCompanyName,
    companyType,
    setCompanyType,
    email,
    setEmail,
    phone,
    setPhone,
    formStatus,
    setFormStatus,
    checkboxCheck,
    setCheckboxCheck,
  } = useContext(FormDetailsContext);
  const today = new Date();
  let data = today.getDate() + "/"+ parseInt(today.getMonth()+1) +"/"+today.getFullYear();

  const dispatch = useDispatch();
  const positionPage = useSelector((state) => state.positionPage.page);
  const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);
  const navigate = useNavigate();
  let logoApp = logoDefault;


  if (logo !== "") {
    logoApp = logo;
  }

  function returnToHome() {
    setCompanyName("");
    setCompanyType("");
    setEmail("");
    setPhone("");
    setCheckboxCheck(false);
    setFormStatus(false);
    dispatch(setPage());
    dispatch(setScore(0));
    navigate("/");
    dispatch(reseteLastValue());
    dispatch(reseteURL());
    dispatch(reseteLastPosition());
    dispatch(reseteQuestions())
  }
  let loader = false;
  if (requestSurvey && requestSurvey[positionPage]) {
    // console.log("SONO LA POSIZIONE: ", requestSurvey[positionPage])
    {
      requestSurvey[positionPage].name === "intro" ||
      requestSurvey[positionPage].name === "result"
        ? (loader = false)
        : (loader = true);
    }
  }
  return (
    <div className="container__tgd__header">
      <div className="tgd__header">
        <div
          className="tgd__header__logo"
          style={{ cursor: "pointer" }}
          onClick={returnToHome}
        >
          <img src={logoApp} alt="logo" />
        </div>
        <div className="tgd__header__title">
          <h1 style={{ color: color }}>{title}</h1>
        </div>
        <div className="tgd__header__data">
          <span style={{visibility: "hidden"}}>Data: {data}</span>
        </div>
      </div>
      <div className="container__tgd__header__progress">
        {loader ? <LinearBarProgress /> : ""}
      </div>
    </div>
  );
}

export default Header;
