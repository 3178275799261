import React, { useContext } from 'react';
import './css/formDetailsClient.css';
import { Checkbox, MenuItem, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormDetailsContext } from '../../../../../context/FormDetailsContext';

function FormDetailsClient() {
  const {companyName, setCompanyName, companyType, setCompanyType, email, setEmail, phone, setPhone, formStatus, setFormStatus, checkboxCheck, setCheckboxCheck} = useContext(FormDetailsContext);
  const positionPage = useSelector((state) => state.positionPage.page);
  const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);
  const [checkEmail, setCheckEmail] = React.useState(true);
  const [currency, setCurrency] = React.useState();

 
  function validateEmail(email){
      setEmail(email);
      let check = email.match(/^([\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+\.)*[\w\!\#$\%\&\'\*\+\-\/\=\?\^\`{\|\}\~]+@((((([a-z0-9]{1}[a-z0-9\-]{0,62}[a-z0-9]{1})|[a-z])\.)+[a-z]{2,6})|(\d{1,3}\.){3}\d{1,3}(\:\d{1,5})?)$/i);
      if(check){
        setCheckEmail(true)
      }else{
        setCheckEmail(false)
      }
  }

if(companyName.length > 3 && email.length > 3 && checkboxCheck && checkEmail && companyType ){
  setFormStatus(false)
}

  if(requestSurvey[positionPage] && requestSurvey[positionPage].answers[0] && requestSurvey[positionPage].answers[0].type){
    const question = requestSurvey[positionPage];
    const data = question.answers[1];

    return (
      <div className='tgd__form'>
     <ul className='tgd__form__list'>
       <li>
       <TextField
          sx={{borderRadius: "4px"}}
          id="nome-azienda"
          label={data[0][0].label}
          variant="filled"
          InputProps={{ disableUnderline: true }}
          onChange={(value) => setCompanyName(value.target.value)}
          fullWidth
          autoFocus
          required
        />
       </li>
       <li>
       <TextField
        sx={{borderRadius: "4px"}}
          id="select-tipo-azienda"
          select
          label={data[1][0].label}
          value={companyType}
          variant="filled"
          InputProps={{ disableUnderline: true }}
          fullWidth
          onChange={(e) => setCompanyType(e.target.value)}
          required
        >
        {data[1][0].items.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option}
            </MenuItem>
          ))}
          </TextField>
       </li>
       <li>
       <TextField
          sx={{borderRadius: "4px"}}
          id="campo-email"
          className={checkEmail ? "" : 'error'}
          label={data[2][0].label}
          type="email"
          variant="filled"
          InputProps={{ disableUnderline: true }}
          onChange={(value) => {validateEmail(value.target.value)}}
          fullWidth
          error={checkEmail ? false : true}
          required
        />
       </li>
       <li>
       <TextField
        sx={{borderRadius: "4px"}}
          id="campo-phone"
          type="number"
          label={data[3][0].label}
          variant="filled"
          InputProps={{ disableUnderline: true }}
          onChange={(value) => setPhone(value.target.value)}
          fullWidth
        />
       </li>
       <li>
       <FormControlLabel
       control={
        <Checkbox 
        name="gilad" 
        label="ciao"
        onChange={(value) => setCheckboxCheck(value.target.checked)}
        required
        />
       }
       label={data[4][0].label}
       />
       </li>
     </ul>
      </div>
    )
  }else{
    return (
      <>
      </>
    )
  }
 
}

export default FormDetailsClient