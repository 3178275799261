import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function PopoverCustom({info}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button variant='contained' color="progress" sx={{ width: "auto", height: "auto", padding: 0}} aria-describedby={id} onClick={handleClick}>
      <span style={{fontSize: "11px", fontWeight: "600", color: "white"}}>Aiuto</span>
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2, maxWidth: "500px" }}><p style={{padding: 0, margin: 0}} dangerouslySetInnerHTML={{__html: info}}></p></Typography>
      </Popover>
    </div>
  );
}