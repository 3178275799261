import React from 'react';
import background from '../../images/background.jpg'
import './home.css';
import Main from './main/Main';


function Home({color, logo, title, email_1, email_2}) {
  return (
    <div className='tgd__home'>
        <img className='tgd__home__img' src={background} alt="background" />
        <div className='tgd__home__overlay'></div>
        <Main color={color} logo={logo} title={title} email_1={email_1} email_2={email_2}/>

    </div>

  );
}

export default Home;
