import React from "react";
import "./body.css";
import "../../../pages/css/pages.css";
import { Routes, Route } from "react-router-dom";
import IntroSurvey from "../../../pages/Intro/IntroSurvey";
import Step_1 from "../../../pages/Question/step-1/Step_1";
import Step_2 from "../../../pages/Question/step-2/Step_2";
import Step_3 from "../../../pages/Question/step-3/Step_3";
import Step_4 from "../../../pages/Question/step-4/Step_4";
import Step_5 from "../../../pages/Question/step-5/Step_5";
import Step_6 from "../../../pages/Question/step-6/Step_6";
import Step_6_children_1 from "../../../pages/Question/step-6/children/answer_1/Step_6_children_1";
import ResultPage from '../../../pages/Result/ResultPage';
import Step_6_children_1_2 from '../../../pages/Question/step-6/children/answer_1/Step_6_children_1_2';
import Step_7 from "../../../pages/Question/step-7/Step_7";
import Step_7_children_1 from "../../../pages/Question/step-7/children/Step_7_children_1";
import Step_8 from '../../../pages/Question/step-8/Step_8';
import Step_8_children_1 from '../../../pages/Question/step-8/children/answer_1/Step_8_children_1';
import Step_9 from '../../../pages/Question/step-9/Step_9';
import Step_10 from '../../../pages/Question/step-10/Step_10';
import Step_11 from '../../../pages/Question/step-11/Step_11';
import Step_12 from '../../../pages/Question/step-12/Step_12';
import Step_6_children_2 from '../../../pages/Question/step-6/children/answer_2/Step_6_children_2';
import Step_6_children_2_2 from '../../../pages/Question/step-6/children/answer_2/Step_6_children_2_2';
import Step_7_children_2 from '../../../pages/Question/step-7/children/Step_7_children_2';
import Step_6_children_3 from '../../../pages/Question/step-6/children/answer_3/Step_6_children_3';
import Step_8_children_2 from '../../../pages/Question/step-8/children/answer_2/Step_8_children_2';
import Step_8_children_3 from '../../../pages/Question/step-8/children/answer_3/Step_8_children_3';
import Step_8_children_4 from '../../../pages/Question/step-8/children/answer_4/Step_8_children_4';
import Step_8_children_5 from '../../../pages/Question/step-8/children/answer_5/Step_8_children_5';
import Step_8_children_6 from '../../../pages/Question/step-8/children/answer_6/Step_8_children_6';


function Body({email_1, email_2}) {
  return (
    <div className="tgd__body">
      <Routes>
        <Route path="/" element={<IntroSurvey />} />
        <Route path="/step-1" element={<Step_1 email_1={email_1} email_2={email_2} />} />
        <Route path="/step-2" element={<Step_2 />} />
        <Route path="/step-3" element={<Step_3 />} />
        <Route path="/step-4" element={<Step_4 />} />
        <Route path="/step-5" element={<Step_5 />} />
        <Route path="/step-6">
          <Route index={true} element={<Step_6 />} />
          <Route path="01" element={<Step_6_children_1 />} />
          <Route path="01/02" element={<Step_6_children_1_2 />} />
          <Route path="01/02/03" element={<Step_6_children_2 />} />
          <Route path="01/02/03/04" element={<Step_6_children_2_2 />} />
          <Route path="01/02/03/04/05" element={<Step_6_children_3 />} />
        </Route>
        <Route path="/step-7">
          <Route index={true} element={<Step_7 />} />
          <Route path="01" element={<Step_7_children_1 />} />
          <Route path="01/02" element={<Step_7_children_2 />} />
        </Route>
        <Route path="/step-8">
          <Route index={true} element={<Step_8 />} />
          <Route path="01" element={<Step_8_children_1 />} />
          <Route path="01/02" element={<Step_8_children_2 />} />
          <Route path="01/02/03" element={<Step_8_children_3 />} />
          <Route path="01/02/03/04" element={<Step_8_children_4 />} />
          <Route path="01/02/03/04/05" element={<Step_8_children_5 />} />
          <Route path="01/02/03/04/05/06" element={<Step_8_children_6 />} />
        </Route>
        <Route path="/step-9" element={<Step_9 />} />
        <Route path="/step-10" element={<Step_10 />} />
        <Route path="/step-11" element={<Step_11 />} />
        <Route path="/step-12" element={<Step_12 />} />
        <Route path="/result" element={<ResultPage email_1={email_1} email_2={email_2}/>} />
      </Routes>
    </div>
  );
}

export default Body;
