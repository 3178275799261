import { createSlice } from "@reduxjs/toolkit";


const PositionSelectSlice = createSlice({
    name: 'lastPosition',
    initialState: [],
    reducers: {
        setLastPosition(state, action) {
            state.push(action.payload)
        },
        deleteLastPosition(state, action) {
            state.pop()
        },
        reseteLastPosition(state, action) {
            return state = []
        },
        updateLastPosition(state, action) {
            state = state.splice(0, 1, action.payload)
        }

    }
})

export const { setLastPosition, deleteLastPosition, reseteLastPosition, updateLastPosition } = PositionSelectSlice.actions
export default PositionSelectSlice.reducer