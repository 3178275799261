import React, { useEffect } from "react";
import "./styles/App.css";
import Home from "./layout/Home/Home";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { sendGetRequestSurvey } from "./app/slices/getRequestSurvey";
import { FormDetailsProvider } from "context/FormDetailsContext";


function App({color, logo, title, email_1, email_2}) {
  // sistema il colore ed il logo
  const dispatch = useDispatch();
  const navigate = useNavigate()

// if(requestSurvey){
//    config = requestSurvey[requestSurvey.length - 1];
//   primaryColor =  config.primaryColor
// }



  useEffect(() => {
    navigate('/')
    dispatch(sendGetRequestSurvey());
  }, [dispatch]);

  return (

      
        <div className="tgd__app">
          <FormDetailsProvider>
          <Home color={color} logo={logo} title={title} email_1={email_1} email_2={email_2}/>
          </FormDetailsProvider>
        </div>

  );
}

export default App;
