import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, FormControlLabel, Radio } from "@mui/material";
import { increment } from "app/slices/NumberOfPageSlice";
import { useNavigate } from "react-router-dom";
import PopoverCustom from "../../../../components/popover/PopoverCustom";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import { useState } from "react";
import { addScore } from "app/slices/calculateTotalScoreSlice";
import { setLastValue } from "app/slices/lastValueAnswer";
import { setURL } from "app/slices/lastURLSlice";
import { setLastPosition } from "app/slices/positionSelectSlice";
import { setQuestions, updateQuestions } from "app/slices/questionsAnswersSlice/questionsAnswers";

function Step_2() {
  const positionPage = useSelector((state) => state.positionPage.page);
  const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);
  const positionSelect = useSelector((state) => state.positionSelect);
  const questionGroup = useSelector((state) => state.questionGroup);
  const [valueAnswer, setValueAnswer] = useState(0);
  const [lastQuestion, setLastQuestion] = useState(null);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const correntPath = window.location.pathname;
  const [checked, setChecked] = useState(false);

  let radioValue = 0;
  let radioChecked = false;
  let groupLastQuestion;

  positionSelect.forEach((item) => {
    if (item.path === correntPath) {
      radioValue = item.value;
      radioChecked = item.checked;
    }
  });
// ciclo le ultime domande salvate per aggiornarle
questionGroup.forEach((item, index) => {
    if (item.path === correntPath) {
      groupLastQuestion = {path: correntPath, question: item.question, answer: item.answer};
    }
});

function answerRequired(checked) {
    radioChecked = checked.target.checked;
    setChecked(checked.target.checked);
  }

  function handleButtonClick() {
    if (radioValue === undefined || radioValue === null || valueAnswer !== 0) {
      dispatch(addScore(parseInt(valueAnswer)));
      dispatch(setLastValue(parseInt(valueAnswer)));
      dispatch(setURL(correntPath));
      dispatch(
        setLastPosition({
          path: correntPath,
          value: valueAnswer,
          checked: true,
        })
      );
        if(groupLastQuestion === undefined){
          dispatch(setQuestions(lastQuestion))
        }else{
          dispatch(updateQuestions(lastQuestion))
        }
    } else {
      dispatch(addScore(parseInt(radioValue)));
      dispatch(setLastValue(parseInt(radioValue)));
      dispatch(setURL(correntPath));
      dispatch(
        setLastPosition({ path: correntPath, value: radioValue, checked: true })
      );
        if(groupLastQuestion === undefined){
          dispatch(setQuestions(groupLastQuestion))
        }else{
          dispatch(updateQuestions(groupLastQuestion))
        }
    }
    dispatch(increment());
    navigate("/step-3");
  }

  if (requestSurvey && requestSurvey[positionPage]) {
    const question = requestSurvey[positionPage];
    return (
      <div className="container__tgd__question">
        <div className="container__tgd__question__main">
          {/* contenuto che cambia */}
          <div className="tgd__step__title">
            {question.info && <PopoverCustom info={question.info} />}
            <h2 dangerouslySetInnerHTML={{ __html: question.title }}></h2>
          </div>
          <div className="tgd__step__body">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue={radioValue}
              >
                {question.answers[1].map((item, index) => (
                  <FormControlLabel
                    key={index}
                    id={index}
                    value={item.value}
                    control={<Radio />}
                    label={item.response}
                    onChange={(value) => {
                      setLastQuestion({path: correntPath, question: question.title, answer: item.response});
                      setValueAnswer(value.target.value);
                      answerRequired(value);
                    }}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
          {/* contenuto che cambia */}
        </div>
        <div className="container__tgd__question__button">
          {/* contenuto che cambia */}
          {radioChecked || checked || question.required === false ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
            >
              Prossima domanda
            </Button>
          ) : (
            <Button
              disabled
              variant="contained"
              color="primary"
              onClick={handleButtonClick}
            >
              Prossima domanda
            </Button>
          )}

          {/* contenuto che cambia */}
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h2>Qualcosa è andato storto!</h2>
      </div>
    );
  }
}

export default Step_2;
