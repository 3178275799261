import React from "react";
import ReactDOM from "react-dom";
import "./styles/reset.css";
import "./styles/index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./utils/serviceWorker";
import { CalculateProgressProvider } from "./context/CalculateProgressContext";
import { TotalScoreProvider } from "context/TotalScoreContext";
import { BrowserRouter } from "react-router-dom";
import { getRequestSurveyService } from './app/service';
import { createTheme, ThemeProvider } from "@mui/material/styles";

getRequestSurveyService().then((data) => {
  const dataConfig = data[0][data[0].length - 1];
  const colorApp = dataConfig.primaryColor;
  const negativeColorApp = dataConfig.primaryColorNegative;
  const logoApp = dataConfig.logo;
  const title = dataConfig.titleSurvey;
  const email_1 = dataConfig.email_to_1;
  const email_2 = dataConfig.email_to_2;



  const theme = createTheme({
    palette: {
      primary: {
        main: colorApp,
        contrastText: negativeColorApp,
      },
      progress: {
        main: "#099C32",
        contrastText: "white",
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        // dark: will be calculated from palette.secondary.main,
        contrastText: "#ffcc00",
      },
      buttonPrimary: {
        main: colorApp,
        contrastText: "white",
      },
      greyLight: {
        main: "#B4B4B4",
      },
    },
  });
  

  ReactDOM.render(

    <React.StrictMode>
          <ThemeProvider theme={theme}>
      <Provider store={store}>
        <CalculateProgressProvider>
          <TotalScoreProvider>
            <BrowserRouter>
              <App color={colorApp} logo={logoApp} title={title} email_1={email_1} email_2={email_2}/>
            </BrowserRouter>
          </TotalScoreProvider>
        </CalculateProgressProvider>
      </Provider>
      </ThemeProvider>
    </React.StrictMode>,

    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
});
