import * as React from "react";
import "./linearBarProgress.css";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import StepAnswers from "./step-answers/StepAnswers";
import { useSelector } from 'react-redux';
import { CalculateProgressContext } from '../../../context/CalculateProgressContext';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 14,
  borderRadius: 10,
  [`&.${linearProgressClasses.determinate}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
  },
}));

export default function LinearBarProgress() {
  const [calculate, setCalculate, pageValue, setPageValue] = React.useContext(CalculateProgressContext);
  const positionPage = useSelector((state) => state.positionPage.page);
  const requestSurvey = useSelector((state) => state.requestSurvey.data[0]);

  function incrementProgress() {
    setCalculate(pageValue * positionPage);
  }

  React.useEffect(() => {
    if (requestSurvey) {
      const number = requestSurvey.length - 3;
      setPageValue(100 / number);
  }
  incrementProgress();
  }, [positionPage]);




  return (
    <div
    className="tgd__progressWrap">
      
      <BorderLinearProgress
        className="tgd__progressWrap__progressLine"
        variant="determinate"
        value={calculate}
        color="progress"
      />
      <StepAnswers />
    </div>
  );
}
